/* index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}
@font-face {
  font-family: Helvetica;
  src: url(../src/Assets/font/Helvetica.ttf);
}


.montserrat {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}
.Helvetica{
  font-family: "Helvetica", sans-serif;
}
.container{
  margin: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.Rectanglehome{
  background: linear-gradient(264.07deg, rgba(37, 206, 209, 0.1) 0.32%, #FFFFFF 33.59%, #25CED1 99.65%);

}
.text-64{
  font-size: 64px;
  line-height: 76px;
}
.text-22{
  font-size: 22px;
}
.margin-my{
  margin: 70px 0px;
}
.text-30{
  font-size: 30px;
}
.text-20{
  font-size: 20px;
}
.text-48 {
  font-size: 48px;
}
.text-25{
  font-size: 25px;
}
.min_home_section{
  padding: 0px 100px;
  gap: 50px;
}
button:hover{
  background-color: black;

}